import React from "react";
import { logo_header } from "../images";

const Header = () => {
    return (
        <div>
            <img src={logo_header} className="center" alt="" loading="lazy" />
        </div>
    );
};

export default Header;