import React from "react";
import { Links } from "./";

const Moto = () => {
    return (
        <div className="container center">
            <div className="row">
                <div className="col-sm">
                    <h1> Mejoramos personas</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <Links />
                </div>
            </div>
        </div>
    );
};

export default Moto;